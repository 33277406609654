.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  border-top: 1px solid #e5e7eb;
  padding: 2rem;
  z-index: 50;
}

.cookie-banner__container {
  max-width: 70%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.cookie-banner__content {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.cookie-banner__icon {
  font-size: 1.25rem;
}

.cookie-banner__text {
  font-size: 15px;
  color: #4b5563;
  font-weight: 700;
  margin: 0;
}

.cookie-banner__link {
  color: #4b5563;
  text-decoration: underline;
  transition: color 0.2s ease;
}

.cookie-banner__link:hover {
  color: #1f2937;
}

.cookie-banner__buttons {
  display: flex;
  gap: 0.75rem;
  flex-shrink: 0;
}

.cookie-banner__button {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.cookie-banner__button--decline {
  background: transparent;
  color: #4b5563;
}

.cookie-banner__button--decline:hover {
  color: #1f2937;
}

.cookie-banner__button--accept {
  background-color: #dc2626;
  color: white;
  border-radius: 0.25rem;
}

.cookie-banner__button--accept:hover {
  background-color: #b91c1c;
}

/* Responsive styles */
@media (max-width: 768px) {
  .cookie-banner__container {
    flex-direction: column;
    align-items: flex-start;
  }

  .cookie-banner__buttons {
    width: 100%;
    justify-content: flex-end;
    margin-top: 1rem;
  }
}

/* Optional animation */
@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.cookie-banner {
  animation: slideUp 0.3s ease-out;
}